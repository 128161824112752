/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Internal dependencies
 */
import Hero from '../../components/hero';
import Layout from '../../components/layout';
import Section from '../../components/section';
import SEO from '../../components/seo';
import ButtonGroup from '../../components/button-group';
import Button from '../../components/button';
import ShapedImage from '../../components/shaped-image';
import Image from '../../components/image';
import Form from '../../components/form';
import OGImage from '../../images/pwp-og-image.png';
import Blockquote from "../../components/blockquote";

const PersonalWebsitePlaybookPage = ( props ) => {
    const {
        location,
    } = props;

    return (
        <Layout
            location={ location }
            noSignUpForm
            noCta
            hideHeader
            hideLinks
        >
            <SEO
                title="The Personal Website Playbook"
                description="Get more leads, revenue and opportunity from your personal website. A course taught by Tom Hirst."
                imageAlt="Personal Website Playbook Video Course"
                imageUrl={ `https://www.tomhirst.com${ OGImage }` }
            />
            <Hero
                imageName="pwp-square.png"
                imageAlt="The Personal Website Playbook"
                regularImage
                borderImage
            >
                <h1>The Personal Website Playbook</h1>
                <p>If you work or spend a lot of time online, <strong>you need your own website</strong>.</p>
                <p>It&apos;s your <strong>modern-day CV</strong> and gateway to a <strong>pipeline of
                    opportunity</strong>:</p>
                <ul>
                    <li style={ { display: 'block', marginBottom: 5 } }><FontAwesomeIcon
                        icon="check" /><strong>Leads</strong> for work <strong>that come to you</strong></li>
                    <li style={ { display: 'block', marginBottom: 5 } }><FontAwesomeIcon
                        icon="check" /><strong>Revenue</strong> across <strong>multiple income streams</strong></li>
                    <li style={ { display: 'block', marginBottom: 5 } }><FontAwesomeIcon icon="check" /><strong>Valuable
                        opportunities</strong> from <strong>interesting people</strong></li>
                </ul>
                <p>Your <strong>personal website</strong> should be the <strong>centrepiece</strong> of
                    your <br /><strong>marketing strategy</strong> in { new Date().getFullYear() }.</p>
                <p>Do you want to know <strong>how to put together a good one</strong>?</p>
                <p><strong><u>Let me show you the playbook</u></strong>.</p>
                <ButtonGroup>
                    <Button to="https://gum.co/GdaxO?wanted=true" icon="download"
                            text="Get the video course now for $99" color="green" external gumroadSingle />
                    <Button to="#tell-me-more" icon="arrow-down" text="Tell me more" anchor />
                </ButtonGroup>

            </Hero>
            <Section id="tell-me-more" narrow>
                <h2>Who am I and what do I know?</h2>
                <ShapedImage
                    name="tom-hirst-headshot.png"
                    alt="Teacher of The Personal Website Playbook, Tom Hirst"
                    pullRight
                    caption="Teacher of The Personal Website Playbook, Tom Hirst"
                    flipped
                    small
                />
                <p>I&apos;ve been using <strong>my personal website</strong> to <strong>generate interest in my
                    services</strong> for <strong>years</strong>.</p>
                <p><strong>This very website</strong> provides fuel for my <strong>six-figure yearly</strong> freelance
                    career.</p>
                <p>It&apos;s been tweaked, modified, tested, added to and removed from in this time.</p>
                <p>Leaving me with <strong>100s of valuable lessons</strong> learned in <strong>what makes personal
                    websites successful</strong>.</p>
                <p>This is what my inbox looks like most months:</p>
                <div className="image">
                    <Image alt="Leads in my inbox" name="leads-in-my-inbox.png" />
                </div>
                <p><strong>278 leads</strong> from 7,012 clicks <strong>in 2020</strong>; all organic, <strong>no paid
                    ads</strong>.</p>
                <div className="image">
                    <Image alt="My website traffic in 2020" name="traffic-2020.png" />
                </div>
                <p>The <strong>biggest lesson</strong> learned of all?</p>
                <p>When your <strong>personal website is good</strong>, you need <strong>less traffic than you
                    think</strong>.</p>
                <p>And <em>because</em> your personal website is good, you can <strong>increase results</strong> by
                    driving more traffic <strong>at any time</strong>.</p>
                <p>The <strong>first step</strong> is <strong>putting together a good personal website</strong>.</p>
                <p>And that&apos;s what this course is all about.</p>
                <Button className="gumroad-button" to="https://gum.co/GdaxO?wanted=true" icon="download"
                        text="Get the video course now for $99" color="green" external gumroadSingle />

            </Section>
            <Section narrow color="light-grey">
                <h2>What you&apos;ll learn</h2>
                <p>Across 12 video modules you&apos;ll learn:</p>
                <ul className="check-list">
                    <li style={ { display: 'block', marginBottom: 20 } }><FontAwesomeIcon icon="check" />Why having a personal website opens you up to<br /><strong>opportunities from all angles</strong></li>
                    <li style={ { display: 'block', marginBottom: 20 } }><FontAwesomeIcon icon="check" />How to <strong>choose the best tools</strong> to assemble your personal website</li>
                    <li style={ { display: 'block', marginBottom: 20 } }><FontAwesomeIcon icon="check" />How to <strong>structure your pages</strong> to <strong>increase conversions</strong></li>
                    <li style={ { display: 'block', marginBottom: 20 } }><FontAwesomeIcon icon="check" />The style and layout <strong>strategies</strong> that <strong>get your content seen</strong></li>
                    <li style={ { display: 'block', marginBottom: 20 } }><FontAwesomeIcon icon="check" /><strong>Which pages</strong> to include and <strong>what to show</strong> on them</li>
                    <li style={ { display: 'block', marginBottom: 20 } }><FontAwesomeIcon icon="check" />How to <strong>gain trust</strong> through your personal website that <strong>gets you work</strong></li>
                    <li style={ { display: 'block', marginBottom: 20 } }><FontAwesomeIcon icon="check" />How to position your personal website to <strong>gain an audience</strong> <br />that will <strong>buy from you</strong></li>
                    <li style={ { display: 'block', marginBottom: 20 } }><FontAwesomeIcon icon="check" />Why messaging raises <strong>personal brand awareness</strong> and how to <br />create yours for your website</li>
                    <li style={ { display: 'block', marginBottom: 20 } }><FontAwesomeIcon icon="check" />How to <strong>write convincingly</strong> to encourage people to <strong>get in touch with you</strong></li>
                    <li style={ { display: 'block', marginBottom: 20 } }><FontAwesomeIcon icon="check" />How to <strong>craft offers</strong> that present value effectively to <strong>get leads</strong> <br />and <strong>make sales</strong></li>
                    <li style={ { display: 'block', marginBottom: 20 } }><FontAwesomeIcon icon="check" />The tactics I use to <strong>drive relevant traffic</strong> to my personal website</li>
                    <li style={ { display: 'block', marginBottom: 20 } }><FontAwesomeIcon icon="check" />Methods to <strong>monetise</strong> your personal website from <strong>different sources simultaneously</strong></li>
                    <li style={ { display: 'block', marginBottom: 20 } }><FontAwesomeIcon icon="check" />How to <strong>test</strong> your personal website&apos;s <strong>performance</strong> and <strong>improve</strong> it over time</li>
                </ul>
                <p><em>Specific modules to be announced.</em></p>
                <Button className="gumroad-button" to="https://gum.co/GdaxO?wanted=true" icon="download"
                        text="Get the video course now for $99" color="green" external gumroadSingle />

            </Section>
            <Section narrow>
                <h2>Praise from buyers</h2>
                <div className="blockquote-list">
                    <Blockquote
                        quote="Valuable information relating to specific and <strong>achievable wins</strong> you can make on your own site today."
                        cite="Scott Spence, Web Developer"
                        imageName="ss-headshot.jpg"
                        imageAlt="Scott Spence"
                        largeQuote
                    />
                    <Blockquote
                        quote="Tom's course generously shares lots of advice and tips that helped him over the years. He includes <strong>tons of examples</strong> that can help anyone accelerate their personal brand."
                        cite="Areej AbuAli, SEO Consultant"
                        imageName="aa-headshot.jpg"
                        imageAlt="Areej AbuAli"
                        mediumQuote
                    />
                    <Blockquote
                        quote="Finally, <strong>a website course without the fluff</strong>. Tom takes it back to first principles and doesn't hold back on sharing his expertise. I'm actually excited to start my new website now. Thanks Tom!"
                        cite="Connor Fowler, Brand Identity Designer"
                        imageName="cf-headshot.jpg"
                        imageAlt="Connor Fowler"
                        mediumQuote
                    />
                    <Blockquote
                        quote="A no-brainer for freelancers and <strong>incredibly useful</strong> for anyone creating an online presence."
                        cite="Dan Spratling, Freelance UX Engineer"
                        imageName="ds-headshot.jpg"
                        imageAlt="Dan Spratling"
                        largeQuote
                    />
                    <Blockquote
                        quote="If you're creating a personal website for the first time or in the process of improving your current one, follow the advice in Tom's Playbook. It's <strong>a fantastic blueprint</strong> for creating a great personal website."
                        cite="Philip Benton, Commercial Photographer"
                        imageName="pb-headshot.jpg"
                        imageAlt="Philip Benton"
                        mediumQuote
                    />
                    <Blockquote
                        quote="Even as an experienced consultant, I <strong>learned a tremendous amount</strong> from this course! I was very satisfied with it, and would highly recommend it."
                        cite="Simon Hoiberg, Software Engineer"
                        imageName="sh-headshot.jpg"
                        imageAlt="Simon Hoiberg"
                        mediumQuote
                    />
                    <Blockquote
                        quote="Tom's playbook was <strong>the roadmap I needed</strong>. Being able to follow a tried-and-tested approach, in a digestible format, made a huge difference and has allowed me to finally finish my personal website."
                        cite="Mike Richardson, SEO Consultant"
                        imageName="mr-headshot.jpg"
                        imageAlt="Mike Richardson"
                        mediumQuote
                    />
                    <Blockquote
                        quote="With my personal website already live, <strong>I used this course to re-evaluate my message to ensure I convert more clients<strong>."
                        cite="Simon Date, Web Developer"
                        imageName="sd-headshot.jpg"
                        imageAlt="Simon Date"
                        mediumQuote
                    />
                    <Blockquote
                        quote="Tom's teaching style is down-to-earth, clear and actionable. <strong>High recommend</strong>."
                        cite="Kevin Cunningham, Developer and Educator"
                        imageName="kc-headshot.jpg"
                        imageAlt="Kevin Cunningham"
                        mediumQuote
                    />
                </div>
                <Button className="gumroad-button" to="https://gum.co/GdaxO?wanted=true" icon="download"
                        text="Get the video course now for $99" color="green" external gumroadSingle />

            </Section>
            <Section narrow color="light-grey">
                <h2>FAQs</h2>
                <h3>Do I need to know how to code?</h3>
                <p>No, because <strong>this is <u>not</u> a technical course</strong>.</p>
                <p>I won&apos;t be teaching you how to code, because there are already 10s of courses out there that
                    will.</p>
                <p>My strength is <strong>helping independent workers market themselves</strong> and this is
                    what <strong>The Personal Website Playbook</strong> teaches.</p>
                <h3>Does my website need to be on a certain platform to benefit?</h3>
                <p><strong>It doesn&apos;t matter what platform you use.</strong></p>
                <p>The Personal Website Playbook&apos;s content applies to <strong>any CMS</strong> or <strong>website builder</strong> and if you build your website entirely from scratch yourself.</p>
                <p>Choosing the platform for your <strong>personal website</strong> isn&apos;t the biggest hurdle;
                    knowing how to <strong>get results from it</strong> is.</p>
                <h3>Who is this course for?</h3>
                <p>Anyone who:</p>
                <ul>
                    <li>Works online</li>
                    <li>Freelances</li>
                    <li>Consults</li>
                    <li>Creates</li>
                    <li>Has a personal brand</li>
                    <li>Wants to raise their profile</li>
                </ul>
                <p>I recommend that you have time to commit and think long-term. Compounding happens over time.</p>
                <h3>Did you pay for ads to get traffic?</h3>
                <p>I&apos;ve never paid for an ad to this website.</p>
                <h3>What&apos;s the format?</h3>
                <p>Slides and demonstrations taught by me on video.</p>

                    <p>12 module files, all downloadable and with free updates for life.</p>

                        <p>You’ll also get an appendix PDF with links to tools, references and resources, plus a PDF of the presentation slides.</p>
                <h3>What if I don&apos;t like it?</h3>
                <p>I created The Personal Website Playbook because there isn't enough quality content on this topic out there.</p>

                <p>If you're worried this course will be of poor quality, should you find no value inside, I'll happily process a refund for you.</p>
                <Button className="gumroad-button" to="https://gum.co/GdaxO?wanted=true" icon="download"
                        text="Get the video course now for $99" color="green" external gumroadSingle />

            </Section>
            <Section narrow>
                <h2>What next?</h2>
                <ShapedImage
                    name="pwp-square.png"
                    alt="The Personal Website Playbook"
                    pullRight
                    flipped
                    small
                />
                <p><strong>Now&apos;s your chance</strong> to <strong>stop procrastinating</strong> on your personal
                    website.</p>
                <p>You do good work and have big ideas.<br />So <strong>let people know about it</strong>.</p>
                <p><strong>The Personal Website Playbook</strong> will give you <strong>clear direction</strong> and
                    help you <strong>feel in control of your marketing</strong>.</p>
                <p>Launching or levelling-up your personal website can get you a <strong>shot you wouldn&apos;t have
                    otherwise had</strong>.</p>
                <p>Open yourself up to a <strong>pipeline of opportunity</strong>.</p>
                <p><strong>Click the button below now!</strong></p>
                <Button className="gumroad-button" to="https://gum.co/GdaxO?wanted=true" icon="download"
                        text="Get the video course now for $99" color="green" external gumroadSingle />

            </Section>
            <Section narrow id="get-updates" color="light-grey">
                <h2>Not ready to pre-order?</h2>
                <Form
                    mailingList
                    buttonText="Get updates"
                    buttonAsideText="Updates, launch details and offers."
                    pathname={ location && location.pathname }
                    list="https://tomhirst.us3.list-manage.com/subscribe/post?u=a6ddc693a59f5b27d84ab73c5&amp;id=09262b8947"
                />
                <h3>Built in public</h3>
                <p>I&apos;m a big believer in building things in public.</p>
                <p>For a behind the scenes look at how I put The Personal Website Playbook together, check out the story on <a href="https://twitter.com/tom_hirst/status/1346441654182543368" target="_blank">Twitter</a>.</p>
            </Section>
        </Layout>
    );
};

PersonalWebsitePlaybookPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

PersonalWebsitePlaybookPage.defaultProps = {
    location: {},
};

export default PersonalWebsitePlaybookPage;
